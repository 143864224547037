// Global CSS files can only be imported into this file. See https://nextjs.org/docs/messages/css-global.
// For SemanticSearch.tsx:
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
// import { hijackEffects } from 'stop-runaway-react-effects'
import { useEffect } from 'react'
import { Workbox } from 'workbox-window'
import { isDevEnv } from '@netpurpose/utils'
import ContextProvider from '#context/ContextProvider'
// For psi/shared/MarkdownField.tsx (exact path required to click through to source):
import '../../../../node_modules/@uiw/react-markdown-preview/markdown.css'
import '../../../../node_modules/@uiw/react-md-editor/markdown-editor.css'
import type { AppProps } from 'next/app'

// Uncomment to monitor infinite loops in useEffect hooks.
// if (process.env.NODE_ENV === 'development') {
//   hijackEffects()
// }

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    if (!isDevEnv() && 'serviceWorker' in navigator) {
      const wb = new Workbox('../../../../service-worker.js')
      wb.register()
    }
  }, [])

  return (
    <>
      <ContextProvider>
        <Component {...pageProps} />
      </ContextProvider>
    </>
  )
}

export default App
