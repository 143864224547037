import { useUnleashContext } from '@unleash/proxy-client-react'
import { FC, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { ApiProvider, createApi, getBaseUrl } from '@netpurpose/api'
import { AlertBannerProvider, ModalProvider, useLoggedIn } from '@netpurpose/core'
import { GlobalStyles, lightTheme } from '@netpurpose/np-ui'
import { config } from '../config'
import { AnalyticsContext, AnalyticsProvider } from './analytics'
import { CurrentUserProvider } from './CurrentUserProvider'
import { QueryClientProvider } from './queryClient'
import { UnleashProvider } from './UnleashProvider'

// TODO: this can be cleaned up at some point, for now i've just put it here
const UpdateUnleashContext: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user: loggedInUser } = useLoggedIn()
  const updateUnleashContext = useUnleashContext()

  // Despite groups being a list, control tool only allows users to be part of one group at a time
  const userGroup = loggedInUser?.groups[0]?.groupId

  useEffect(() => {
    // userId won't accept null, so default to undefined.
    updateUnleashContext({ ...(userGroup ? { userId: userGroup } : {}) })
    // Ideally we'd have updateUnleashContext in the dependency array, but we only
    // want to update the unleash context if the email has changed, and if unleash
    // is unable to fetch toggles for any reason then the context keeps changing,
    // causing an infinite loop and hammering the API.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup])

  return <>{children}</>
}

type Props = {
  children: React.ReactNode
}

const ContextProvider: FC<Props> = ({ children }) => (
  <UnleashProvider>
    <CurrentUserProvider>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <AlertBannerProvider>
          <QueryClientProvider>
            <ApiProvider
              apiConfig={{
                axios: {
                  getAxiosInstance: createApi,
                  factsServiceBaseUrl: getBaseUrl(config.factsHost),
                  modellingServiceBaseUrl: getBaseUrl(config.modellingHost),
                  usersServiceBaseUrl: getBaseUrl(config.usersHost),
                },
              }}
            >
              <UpdateUnleashContext>
                <AnalyticsProvider>
                  <ModalProvider>{children}</ModalProvider>
                </AnalyticsProvider>
              </UpdateUnleashContext>
            </ApiProvider>
          </QueryClientProvider>
        </AlertBannerProvider>
      </ThemeProvider>
    </CurrentUserProvider>
  </UnleashProvider>
)

export default ContextProvider

// used for tests
export const TestContextProvider: FC<Props> = ({ children }) => (
  <CurrentUserProvider>
    <ThemeProvider theme={lightTheme}>
      <AlertBannerProvider>
        <QueryClientProvider>
          <ApiProvider
            apiConfig={{
              axios: {
                getAxiosInstance: createApi,
                factsServiceBaseUrl: getBaseUrl(config.factsHost),
                modellingServiceBaseUrl: getBaseUrl(config.modellingHost),
                usersServiceBaseUrl: getBaseUrl(config.usersHost),
              },
            }}
          >
            <AnalyticsContext.Provider value={null}>
              <ModalProvider>{children}</ModalProvider>
            </AnalyticsContext.Provider>
          </ApiProvider>
        </QueryClientProvider>
      </AlertBannerProvider>
    </ThemeProvider>
  </CurrentUserProvider>
)
