import {
  QueryClientProvider as DefaultQueryClientProvider,
  Mutation,
  MutationCache,
  Query,
  QueryCache,
  QueryClient,
  QueryKey,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FC, useCallback, useMemo } from 'react'
import { IAlertBannerContext, useAlertBanner } from '@netpurpose/core'
import { getMessageFromErrorResponse, noop } from '@netpurpose/utils'
import { AlertBannerType } from '#components/common/AlertBanner'

type QueryCacheErrorHandler = QueryCache['config']['onError']

type MutationCacheErrorHandler = MutationCache['config']['onError']

const getQueryClient = (
  handleQueryError: QueryCacheErrorHandler,
  handleMutationError: MutationCacheErrorHandler,
) => {
  return new QueryClient({
    queryCache: new QueryCache({
      onError: handleQueryError || noop,
    }),
    mutationCache: new MutationCache({
      onError: handleMutationError || noop,
    }),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })
}

const handleError = (
  err: unknown,
  queryOrMutation:
    | Parameters<NonNullable<QueryCacheErrorHandler>>[1]
    | Parameters<NonNullable<MutationCacheErrorHandler>>[3],
  showAlertBanner: IAlertBannerContext<AlertBannerType>['showAlertBanner'],
) => {
  if (queryOrMutation.meta?.['isGlobalErrorHandlingDisabled']) {
    return
  }
  if (err instanceof AxiosError) {
    if (err?.message === 'Network Error') {
      return showAlertBanner(AlertBannerType.NetworkError)
    }
    if (err.response) {
      return showAlertBanner(AlertBannerType.ServerError, getMessageFromErrorResponse(err.response))
    }
  }
  // @ts-expect-error - err.body is unknown type
  const errMessage = err?.body?.msg || err?.body?.detail
  if (errMessage) {
    return showAlertBanner(AlertBannerType.ServerError, errMessage)
  }
  return showAlertBanner(AlertBannerType.GeneralError)
}

export const QueryClientProvider: FC<{ children: React.ReactNode }> = ({ children, ...props }) => {
  const { showAlertBanner } = useAlertBanner<AlertBannerType>()

  // TODO: update types
  const handleQueryError: QueryCacheErrorHandler = useCallback(
    (err: unknown, query: Query<unknown, unknown, unknown, QueryKey>) =>
      handleError(err, query, showAlertBanner),
    [showAlertBanner],
  )

  // TODO: update types
  const handleMutationError: MutationCacheErrorHandler = useCallback(
    (
      err: unknown,
      _variables: any,
      _context: any,
      mutation: Mutation<unknown, unknown, unknown, unknown>,
    ) => handleError(err, mutation, showAlertBanner),
    [showAlertBanner],
  )

  const queryClient = useMemo(
    () => getQueryClient(handleQueryError, handleMutationError),
    [handleQueryError, handleMutationError],
  )

  return (
    <DefaultQueryClientProvider client={queryClient} {...props}>
      {children}
    </DefaultQueryClientProvider>
  )
}
