import { ErrorEvent, EventHint } from '@sentry/nextjs'
import { isProductionEnv } from '@netpurpose/utils'

// Configuration for the event rate threshold
const EVENT_RATE_THRESHOLD = 5 // The number of events per minute considered as a sudden influx
const SAMPLE_RATE_NORMAL = 1.0 // Sample rate when events are below the threshold
const SAMPLE_RATE_THROTTLED = 0.1 // Sample rate when events exceed the threshold
const INTERVAL = 60000 // 1 minute in milliseconds

let eventQueue: number[] = [] // Stores event timestamps

const calculateCustomSampleRate = () => {
  const now = Date.now()

  // Log the current event (implicitly recording it)
  eventQueue.push(now)

  // Remove events older than the last interval
  eventQueue = eventQueue.filter((timestamp) => now - timestamp <= INTERVAL)

  // Adjust sample rate based on the event count
  const sampleRate =
    eventQueue.length < EVENT_RATE_THRESHOLD ? SAMPLE_RATE_NORMAL : SAMPLE_RATE_THROTTLED

  return sampleRate
}

export const sentryConfig = {
  enabled: process.env['NEXT_PUBLIC_MONITORING_ENABLED']
    ? process.env['NEXT_PUBLIC_MONITORING_ENABLED'] === 'true'
    : false,
  dsn: 'https://cafc43e96aab4d53a1dcbe96ac4d1800@o581227.ingest.sentry.io/6582105',
  environment: isProductionEnv() ? 'production' : 'preview',
  beforeSend(event: ErrorEvent, hint: EventHint) {
    // swallow recaptcha error - https://app.asana.com/0/1199950755555247/1200416793436331
    if (hint?.originalException === 'Timeout') {
      return null
    }
    return event
  },
  // Any errors about the "ResizeObserver loop" limit exceeded or completed with
  // undelivered notifications aren't actionable as they seem to be buried within
  // the Ant Design library and don't seem to cause any issues for us anyway.
  ignoreErrors: [/ResizeObserver loop/],
  // If we have an explosion of Sentry errors, we can calculate a custom sampling
  // rate to avoid sending every single error to Sentry and therefore potentially
  // wiping out our transaction quota.
  tracesSampler: calculateCustomSampleRate,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
}
